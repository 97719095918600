// Import packages
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';

// Import assets
import '../../assets/styles/homepage/services.scss';
import {ReactComponent as DownIcon} from "../../assets/images/icons/ic_arrow_down.svg";

// Import components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {services} from "../../constants/staticData";

const Services = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [subTitle, setSubTitle] = useState({
        text: props?.staticTexts?.services_description,
        seeAll: false,
    });
    const [description, setDescription] = useState({
        text: props?.staticTexts[`service_${currentIndex+1}_item_description`],
        seeAll: false,
    });
    useEffect(() => {
    }, [props.selectedLanguage]);
    const {staticTexts} = props;

    useEffect(()=> {
        if(window.innerWidth < 1050){
            setSubTitle({
                text: props?.staticTexts?.services_description.slice(0, 200),
                seeAll: (props?.staticTexts?.services_description.length > 200)
            });
            setDescription({
                text: props?.staticTexts[`service_${currentIndex+1}_item_description`].slice(0, 200),
                seeAll: (props?.staticTexts[`service_${currentIndex+1}_item_description`].length > 200),
            })
        }
    }, []);

    return <section className={`services-wrapper`} id={'services'}>
        <div className="top-side">
            <div className="description">
                <div className="services-title">{props?.staticTexts?.services_title}</div>
                {/*<div className="services-sub-title">{props?.staticTexts?.services_description}</div>*/}
                <div className="services-sub-title">
                    {subTitle?.text}
                   {(props?.staticTexts?.services_description?.length > 200) &&
                   <span onClick={() => setSubTitle({
                        text: !subTitle?.seeAll ? subTitle?.text?.slice(0, 200) : props?.staticTexts?.services_description,
                        seeAll: !subTitle?.seeAll,
                    })}>
                       {subTitle?.seeAll ? `...${staticTexts?.services_see_more_button}` : staticTexts?.services_see_less_button}
                       <DownIcon title={''}/>
                   </span>}
                </div>
            </div>
            <div className="selected-image">
                <div className="selected-title">{staticTexts[`service_${currentIndex+1}_item_title`]}</div>
                <div className="image-proportion">
                    <img src={require(`../../assets/images/services/select_${currentIndex + 1}.jpg`)} alt=""/>
                </div>
            </div>
            <div className="services-list">
                {
                    !!services.length && services.map((item, index) => {
                        return <div key={item?.id}
                                    className={`service-card ${index === currentIndex ? 'active-card' : ''}`}
                                    onClick={() => setCurrentIndex(index)}>
                            <div className="service-title">{staticTexts[`service_${index+1}_item_title`]}</div>
                            <div className="image-wrapper">
                                <div className="image-proportion">
                                    <img src={require(`../../assets/images/services/${item.mediaMain?.path}`)}
                                         className="product-card-img"
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>

        <div className="bottom-side">
            <div className="services-list">
                {
                    !!services.length && services.map((item, index) => {
                        return <div key={`${item?.id}${index}`}
                                    className={`service-card ${index === currentIndex ? 'active-card' : ''}`}
                                    onClick={() => setCurrentIndex(index)}>
                            <div className="service-title">{staticTexts[`service_${index+1}_item_title`]}</div>
                            <div className="image-wrapper">
                                <div className="image-proportion">
                                    <img src={require(`../../assets/images/services/${item.mediaMain?.path}`)}
                                         className="product-card-img"
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="items-description">
                {staticTexts[`service_${currentIndex+1}_item_description`]}
                {/*{description?.text}*/}
             {props?.staticTexts[`service_${currentIndex+1}_item_description`].length > 200 &&
             <span onClick={() => setSubTitle({
                    text: !description?.seeAll ? description?.text?.slice(0, 200) : staticTexts[`service_${currentIndex+1}_item_description`],
                    seeAll: !description?.seeAll,
                })}>
                 {description?.seeAll ? `...${staticTexts?.services_see_more_button}` : staticTexts?.services_see_less_button}
                 <DownIcon title={''}/>
             </span>}
            </div>
            <div className="selected-image">
                <div className="selected-title">{staticTexts[`service_${currentIndex+1}_item_title`]}</div>
                <div className="image-proportion">
                    <img src={require(`../../assets/images/services/select_${currentIndex + 1}.jpg`)} alt=""/>
                </div>
            </div>
        </div>
    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {};

export default  connect(mapStateToProps, mapDispatchToProps)(Services);