export const StaticTexts = {
    en: {
        //Not Found Page
        not_found_empty_view: "Sorry, page not found-en",

        //Header
        nav_our_fuels: "Մեր Հասցեները-en",
        service_center: "Սպասարկման կենտրոն-en",
        menu_item_main: "Գլխավոր-en",
        menu_item_services: "Ծառայություններ-en",
        menu_item_fuels: "Լցակայաններ-en",
        menu_item_apps: "Հավելվածներ-en",
        menu_item_contacts: "Հայտ-en",
        menu_item_partners: "Գործընկերներ-en",

        //Mobile menu
        mobile_menu_language: "Լեզու-en",

        //Main
        main_see_video: "Տեսնել Հոլովակ -en",
        slider_1_item_title: "Ձերք բերեք վառելիք շուկայական արժեքից մատչելի գնով -en",
        slider_1_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում: -en",

        //Services
        services_title: 'Բոլոր անհրաժեշտ ծառայությունները մքենայի համար -en',
        services_description: 'Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի\n' +
            '                    լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման Վառելիքի լիցքավորման առաջին առցանց\n' +
            '                    հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի\n' +
            '                    լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման -en',
        services_see_more_button: 'See more -en',
        services_see_less_button: 'See less -en',
        service_1_item_title: 'Ծառայություններ -en',
        service_1_item_description: 'Օգտվեք Ձեր մքենային առնչվող բազմաբնույթ ծառայություններից -en',
        service_2_item_title: 'Վառելիք -en',
        service_2_item_description: 'Վառելիքի առցանց և արագ ձեռքբերում շուկայական արժեքից ցածր գնով -en',
        service_3_item_title: 'Առցանց խանութ -en',
        service_3_item_description: 'Ձեռք բերեք ձեր մքենայի համար բոլորանհրաժեշտ պարագաները -en',
        service_4_item_title: 'Լցակայաններ ՀՀ տարացքով -en',
        service_4_item_description: 'Վառելիքի առցանց և արագ ձեռքբերում շուկայական արժեքից ցածր գնով -en',

        //Fuels
        fuels_title: "Մենք առաջարկում ենք -en",
        fuels_first_banner_title: "Լցակայաններ ՀՀ ողջ տարացքում -en",
        fuels_first_banner_1_item: "Վարորդների տեղաշարժի լիարժեք վերահսկում -en",
        fuels_first_banner_2_item: "Ավտոպարկի լիարժեք ղեկավարում -en",
        fuels_first_banner_3_item: "Անհատական կառավարման հարթակ -en",
        fuels_first_banner_4_item: "Մեքենանայի վրա կատարված ծախսերի կառավարման հարմարավետ լուծում -en",
        fuels_second_banner_title: "Լցակայաններ ՀՀ ողջ տարացքում -en",
        fuels_second_banner_1_item: "Վարորդների տեղաշարժի լիարժեք վերահսկում -en",
        fuels_second_banner_2_item: "Ավտոպարկի լիարժեք ղեկավարում -en",
        fuels_second_banner_3_item: "Անհատական կառավարման հարթակ -en",
        fuels_second_banner_4_item: "Մեքենանայի վրա կատարված ծախսերի կառավարման հարմարավետ լուծում -en",
        fuels_list_1_item_title: "Սպասարկում  24/7 ռեժիմով- en",
        fuels_list_1_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- en",
        fuels_list_2_item_title: "ԱԱՀ հշվառում- en",
        fuels_list_2_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- en",
        fuels_list_3_item_title: "Լցակայանների ահռելի ցանց- en",
        fuels_list_3_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- en",
        fuels_list_4_item_title: "Մեքենայի խնդրների լուծում- en",
        fuels_list_4_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- en",
        fuels_list_5_item_title: "Գումարի խնայում- en",
        fuels_list_5_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- en",
        fuels_list_6_item_title: "Ծախսերի առցանց վերահսկում- en",
        fuels_list_6_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- en",

        //Apps
        apps_title: "Ճանաչված ապրանքանիշեր -en",
        apps_info_title: "Վառելիքի լիցքավորման առցանց հարթակ -en",
        apps_info_description: "Ցանկանում եք խնայել գումար վառելիք ձեռք բերելիս՝ գրանցվեք եւ ներբեռնեք հավելվածը հենց հիմա։\n" +
            "                        Դարձիր Petrol.am - իհաճախորդ . -en",

        //Contacts
        contacts_title: "Իրավաբանական անձանց առցանց պայմանագրի կնքում -en",
        contacts_page_placeholder_firstName: "Անուն ազգանուն -en",
        contacts_page_placeholder_company_name: "Կազմակերպության անվանում -en",
        contacts_page_placeholder_tax_code_name: "ՀՎՀՀ -en",
        contacts_page_placeholder_email: "Էլ․ հասցե -en",
        contacts_page_placeholder_phoneNumber: "Հեռախոսահամար -en",
        contacts_page_placeholder_message: "Գրել տեքստ… -en",
        contacts_page_btn_send_message: "Ուղարկել -en",
        contacts_cards_1_item: "Լրացրեք օնլայն հայտ -en",
        contacts_cards_2_item: "Ստացեք հաստատում մեր կողմից -en",
        contacts_cards_3_item: "Ստացեք հարմարավետ հարթակ -en",
        contacts_cards_4_item: "Լիցքավորեք ձեր հաշիվը -en",
        contacts_cards_5_item: "Օգտվեք կառավարման վահանակից -en",

        //Partners
        partners_title: "Մեր գործընկերները -en",

        //Map Modal
        map_modal_title: "Մուտքագրեք հասցեն-en",
        map_modal_label_residence_street: "Քաղաք, փողոց-en",
        map_modal_label_building: "Շենք-en",
        map_modal_label_building_entrance: "Մուտք-en",
        map_modal_label_building_floor: "Հարկ-en",
        map_modal_label_building_house: "Բնակարան-en",
        map_modal_btn_cancel: "Չեղարկել-en",
        map_modal_btn_save: "Պահպանել-en",

        //Footer
        footer_social_title: "Հետևիր մեզ -en",
        footer_section_copyright_text: "© Copyright 2020 «petrol.am» -en",
        footer_section_developed_by_text: "Մշակումը ՝ -en",

    },
    hy: {
        //Not Found Page
        not_found_empty_view: "Ներեցեք, էջը չի գտնվել",

        //Header
        nav_our_fuels: "Մեր Հասցեները",
        service_center: "Սպասարկման կենտրոն",
        menu_item_main: "Գլխավոր",
        menu_item_services: "Ծառայություններ",
        menu_item_fuels: "Լցակայաններ",
        menu_item_apps: "Հավելվածներ",
        menu_item_contacts: "Հայտ",
        menu_item_partners: "Գործընկերներ",

        //Mobile menu
        mobile_menu_language: "Լեզու",

        //Main
        main_see_video: "Տեսնել Հոլովակ",
        slider_1_item_title: "Ձերք բերեք վառելիք շուկայական արժեքից մատչելի գնով",
        slider_1_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում:",

        //Services
        services_title: 'Բոլոր անհրաժեշտ ծառայությունները մքենայի համար',
        services_description: 'Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի\n' +
            '                    լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման Վառելիքի լիցքավորման առաջին առցանց\n' +
            '                    հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի\n' +
            '                    լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման',
        services_see_more_button: 'ավելին',
        services_see_less_button: 'փակել',
        service_1_item_title: 'Ծառայություններ',
        service_1_item_description: 'Օգտվեք Ձեր մքենային առնչվող բազմաբնույթ ծառայություններից',
        service_2_item_title: 'Վառելիք',
        service_2_item_description: 'Վառելիքի առցանց և արագ ձեռքբերում շուկայական արժեքից ցածր գնով',
        service_3_item_title: 'Առցանց խանութ',
        service_3_item_description: 'Ձեռք բերեք ձեր մքենայի համար բոլորանհրաժեշտ պարագաները',
        service_4_item_title: 'Լցակայաններ ՀՀ տարացքով',
        service_4_item_description: 'Վառելիքի առցանց և արագ ձեռքբերում շուկայական արժեքից ցածր գնով',

        //Fuels
        fuels_title: "Մենք առաջարկում ենք",
        fuels_first_banner_title: "Լցակայաններ ՀՀ ողջ տարացքում",
        fuels_first_banner_1_item: "Վարորդների տեղաշարժի լիարժեք վերահսկում",
        fuels_first_banner_2_item: "Ավտոպարկի լիարժեք ղեկավարում",
        fuels_first_banner_3_item: "Անհատական կառավարման հարթակ",
        fuels_first_banner_4_item: "Մեքենանայի վրա կատարված ծախսերի կառավարման հարմարավետ լուծում",
        fuels_second_banner_title: "Լցակայաններ ՀՀ ողջ տարացքում",
        fuels_second_banner_1_item: "Վարորդների տեղաշարժի լիարժեք վերահսկում",
        fuels_second_banner_2_item: "Ավտոպարկի լիարժեք ղեկավարում",
        fuels_second_banner_3_item: "Անհատական կառավարման հարթակ",
        fuels_second_banner_4_item: "Մեքենանայի վրա կատարված ծախսերի կառավարման հարմարավետ լուծում",
        fuels_list_1_item_title: "Սպասարկում  24/7 ռեժիմով",
        fuels_list_1_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",
        fuels_list_2_item_title: "ԱԱՀ հշվառում",
        fuels_list_2_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",
        fuels_list_3_item_title: "Լցակայանների ահռելի ցանց",
        fuels_list_3_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",
        fuels_list_4_item_title: "Մեքենայի խնդրների լուծում",
        fuels_list_4_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",
        fuels_list_5_item_title: "Գումարի խնայում",
        fuels_list_5_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",
        fuels_list_6_item_title: "Ծախսերի առցանց վերահսկում",
        fuels_list_6_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",

        //Apps
        apps_title: "Ճանաչված ապրանքանիշեր",
        apps_info_title: "Վառելիքի լիցքավորման առցանց հարթակ",
        apps_info_description: "Ցանկանում եք խնայել գումար վառելիք ձեռք բերելիս՝ գրանցվեք եւ ներբեռնեք հավելվածը հենց հիմա։\n" +
            "                        Դարձիր Petrol.am - իհաճախորդ .",

        //Contacts
        contacts_title: "Իրավաբանական անձանց առցանց պայմանագրի կնքում",
        contacts_page_placeholder_firstName: "Անուն ազգանուն",
        contacts_page_placeholder_company_name: "Կազմակերպության անվանում",
        contacts_page_placeholder_tax_code_name: "ՀՎՀՀ",
        contacts_page_placeholder_email: "Էլ․ հասցե",
        contacts_page_placeholder_phoneNumber: "Հեռախոսահամար",
        contacts_page_placeholder_message: "Գրել տեքստ…",
        contacts_page_btn_send_message: "Ուղարկել",
        contacts_cards_1_item: "Լրացրեք օնլայն հայտ",
        contacts_cards_2_item: "Ստացեք հաստատում մեր կողմից",
        contacts_cards_3_item: "Ստացեք հարմարավետ հարթակ",
        contacts_cards_4_item: "Լիցքավորեք ձեր հաշիվը",
        contacts_cards_5_item: "Օգտվեք կառավարման վահանակից",

        //Partners
        partners_title: "Մեր գործընկերները",

        //Map Modal
        map_modal_title: "Մուտքագրեք հասցեն",
        map_modal_label_residence_street: "Քաղաք, փողոց",
        map_modal_label_building: "Շենք",
        map_modal_label_building_entrance: "Մուտք",
        map_modal_label_building_floor: "Հարկ",
        map_modal_label_building_house: "Բնակարան",
        map_modal_btn_cancel: "Չեղարկել",
        map_modal_btn_save: "Պահպանել",

        //Footer
        footer_social_title: "Հետևիր մեզ",
        footer_section_copyright_text: "© Copyright 2020 «petrol.am»",
        footer_section_developed_by_text: "Մշակումը ՝",
    },
    ru: {
        //Not Found Page
        not_found_empty_view: "Ներեցեք, էջը չի գտնվել-ru",

        //Header
        nav_our_fuels: "Մեր Հասցեները-ru",
        service_center: "Սպասարկման կենտրոն-ru",
        menu_item_main: "Գլխավոր-ru",
        menu_item_services: "Ծառայություններ-ru",
        menu_item_fuels: "Լցակայաններ-ru",
        menu_item_apps: "Հավելվածներ-ru",
        menu_item_contacts: "Հայտ-ru",
        menu_item_partners: "Գործընկերներ-ru",

        //Mobile menu
        mobile_menu_language: "Լեզու-ru",

        //Main
        main_see_video: "Տեսնել Հոլովակ -ru",
        slider_1_item_title: "Ձերք բերեք վառելիք շուկայական արժեքից մատչելի գնով -ru",
        slider_1_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում: -ru",

        //Services
        services_title: 'Բոլոր անհրաժեշտ ծառայությունները մքենայի համար -ru',
        services_description: 'Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի\n' +
            '                    լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման Վառելիքի լիցքավորման առաջին առցանց\n' +
            '                    հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի\n' +
            '                    լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման -ru',
        services_see_more_button: 'ավելին -ru',
        services_see_less_button: 'փակել -ru',
        service_1_item_title: 'Ծառայություններ -ru',
        service_1_item_description: 'Օգտվեք Ձեր մքենային առնչվող բազմաբնույթ ծառայություններից -ru',
        service_2_item_title: 'Վառելիք -ru',
        service_2_item_description: 'Վառելիքի առցանց և արագ ձեռքբերում շուկայական արժեքից ցածր գնով -ru',
        service_3_item_title: 'Առցանց խանութ -ru',
        service_3_item_description: 'Ձեռք բերեք ձեր մքենայի համար բոլորանհրաժեշտ պարագաները -ru',
        service_4_item_title: 'Լցակայաններ ՀՀ տարացքով -ru',
        service_4_item_description: 'Վառելիքի առցանց և արագ ձեռքբերում շուկայական արժեքից ցածր գնով -ru',

        //Fuels
        fuels_title: "Մենք առաջարկում ենք -ru",
        fuels_first_banner_title: "Լցակայաններ ՀՀ ողջ տարացքում -ru",
        fuels_first_banner_1_item: "Վարորդների տեղաշարժի լիարժեք վերահսկում -ru",
        fuels_first_banner_2_item: "Ավտոպարկի լիարժեք ղեկավարում -ru",
        fuels_first_banner_3_item: "Անհատական կառավարման հարթակ -ru",
        fuels_first_banner_4_item: "Մեքենանայի վրա կատարված ծախսերի կառավարման հարմարավետ լուծում -ru",
        fuels_second_banner_title: "Լցակայաններ ՀՀ ողջ տարացքում -ru",
        fuels_second_banner_1_item: "Վարորդների տեղաշարժի լիարժեք վերահսկում -ru",
        fuels_second_banner_2_item: "Ավտոպարկի լիարժեք ղեկավարում -ru",
        fuels_second_banner_3_item: "Անհատական կառավարման հարթակ -ru",
        fuels_second_banner_4_item: "Մեքենանայի վրա կատարված ծախսերի կառավարման հարմարավետ լուծում -ru",
        fuels_list_1_item_title: "Սպասարկում  24/7 ռեժիմով- ru",
        fuels_list_1_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- ru",
        fuels_list_2_item_title: "ԱԱՀ հշվառում- ru",
        fuels_list_2_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- ru",
        fuels_list_3_item_title: "Լցակայանների ահռելի ցանց- ru",
        fuels_list_3_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- ru",
        fuels_list_4_item_title: "Մեքենայի խնդրների լուծում- ru",
        fuels_list_4_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- ru",
        fuels_list_5_item_title: "Գումարի խնայում- ru",
        fuels_list_5_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- ru",
        fuels_list_6_item_title: "Ծախսերի առցանց վերահսկում- ru",
        fuels_list_6_item_description: "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:- ru",

        //Apps
        apps_title: "Ճանաչված ապրանքանիշեր -ru",
        apps_info_title: "Վառելիքի լիցքավորման առցանց հարթակ -ru",
        apps_info_description: "Ցանկանում եք խնայել գումար վառելիք ձեռք բերելիս՝ գրանցվեք եւ ներբեռնեք հավելվածը հենց հիմա։\n" +
            "                        Դարձիր Petrol.am - իհաճախորդ . -ru",

        //Contacts
        contacts_title: "Իրավաբանական անձանց առցանց պայմանագրի կնքում -ru",
        contacts_page_placeholder_firstName: "Անուն ազգանուն -ru",
        contacts_page_placeholder_company_name: "Կազմակերպության անվանում -ru",
        contacts_page_placeholder_tax_code_name: "ՀՎՀՀ -ru",
        contacts_page_placeholder_email: "Էլ․ հասցե -ru",
        contacts_page_placeholder_phoneNumber: "Հեռախոսահամար -ru",
        contacts_page_placeholder_message: "Գրել տեքստ… -ru",
        contacts_page_btn_send_message: "Ուղարկել -ru",
        contacts_cards_1_item: "Լրացրեք օնլայն հայտ -ru",
        contacts_cards_2_item: "Ստացեք հաստատում մեր կողմից -ru",
        contacts_cards_3_item: "Ստացեք հարմարավետ հարթակ -ru",
        contacts_cards_4_item: "Լիցքավորեք ձեր հաշիվը -ru",
        contacts_cards_5_item: "Օգտվեք կառավարման վահանակից -ru",

        //Partners
        partners_title: "Մեր գործընկերները -ru",

        //Map Modal
        map_modal_title: "Մուտքագրեք հասցեն-ru",
        map_modal_label_residence_street: "Քաղաք, փողոց-ru",
        map_modal_label_building: "Շենք-ru",
        map_modal_label_building_entrance: "Մուտք-ru",
        map_modal_label_building_floor: "Հարկ-ru",
        map_modal_label_building_house: "Բնակարան-ru",
        map_modal_btn_cancel: "Չեղարկել-ru",
        map_modal_btn_save: "Պահպանել-ru",

        //Footer
        footer_social_title: "Հետևիր մեզ -ru",
        footer_section_copyright_text: "© Copyright 2020 «petrol.am» -ru",
        footer_section_developed_by_text: "Մշակումը ՝ -ru",
    }
};
