// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import isEmail from "validator/es/lib/isEmail";
//import assets
import "../../assets/styles/homepage/contacts.scss";
import {ReactComponent as Item_1} from "../../assets/images/contacts/1.svg";
import {ReactComponent as Item_2} from "../../assets/images/contacts/2.svg";
import {ReactComponent as Item_3} from "../../assets/images/contacts/3.svg";
import {ReactComponent as Item_4} from "../../assets/images/contacts/4.svg";
import {ReactComponent as Item_5} from "../../assets/images/contacts/5.svg";
import {ReactComponent as TickIcon} from '../../assets/images/icons/ic_tick.svg';
import {LoadingOutlined} from '@ant-design/icons';

// Import Components
import {contacts} from "../../constants/staticData";
import {InputGroup} from "../uiElements/inputGroup";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail} from "../../redux/actions";

const imgArr = [<Item_1 title={''}/>, <Item_2 title={''}/>, <Item_3 title={''}/>, <Item_4 title={''}/>,
    <Item_5 title={''}/>];

const Contacts = (props) => {
    const {staticTexts, requestLoading} = props;
    const [tick, setTick] = useState(false);
    const [contact, setContacts] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
        taxCode: '',
    });
    const [errors, setErrors] = useState({
        name: false,
        company: false,
        email: false,
        phone: false,
        message: false,
        taxCode: false,
    });

    const getInputValues = (e) => {
        const {name, value} = e.target;

        const phoneRegExp = /^[+\d]\d*$/;

        if ((name === 'phone' || name === 'taxCode') && value && !phoneRegExp.test(value)) {
            return;
        }

        setContacts({
            ...contact,
            [name]: value
        })
    };

    const validate = () => {
        const errors = {};
        let result = true;
        const taxCodeRegExp = /^[0-9]{8}$/;
        Object.keys(contact).forEach(key => {
            if (key === 'taxCode' && contact && !taxCodeRegExp.test(contact.taxCode)) {
                errors[key] = true;
                result = false;
            }
            if (!contact[key]
                || (key === 'email' && !isEmail(contact?.email))
                || (key === 'phone' && contact.phone?.length < 9)
            ) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        return result;
    };


    const sendMessage = () => {
        if (validate()) {
            props.SendEmail(contact).then(() => {
                setContacts({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setTick(true);
                showTick();
            })
        }
    };

    const showTick = () => {
        setTimeout(
            () => setTick(false),
            2000
        )
    };

    return <section className={`contacts-wrapper`} id='contacts'>
        <div className="contacts-title">{staticTexts?.contacts_title}</div>

        <div className="form-wrapper">
            <div className="wrapper-proportion">
                <div className="form-content">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'name'}
                        placeholder={staticTexts?.contacts_page_placeholder_firstName}
                        value={contact.name}
                        error={errors.name}
                        maxLength={100}
                        onKeyDown={(e) => {
                            // e.key === 'Enter' && this.sendMessage()
                        }}
                        onChange={getInputValues}
                    />
                    <div className='row'>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'company'}
                            value={contact.company}
                            error={errors.company}
                            placeholder={staticTexts?.contacts_page_placeholder_company_name}
                            maxLength={50}
                            onKeyDown={(e) => {
                                // e.key === 'Enter' && sendMessage()
                            }}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'taxCode'}
                            value={contact.taxCode}
                            error={errors.taxCode}
                            placeholder={staticTexts?.contacts_page_placeholder_tax_code_name}
                            maxLength={8}
                            onKeyDown={(e) => {
                                // e.key === 'Enter' && sendMessage()
                            }}
                            onChange={getInputValues}
                        />
                    </div>
                    <div className='row'>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'email'}
                            value={contact.email}
                            error={errors.email}
                            placeholder={staticTexts?.contacts_page_placeholder_email}
                            maxLength={50}
                            onKeyDown={(e) => {
                                // e.key === 'Enter' && sendMessage()
                            }}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'phone'}
                            value={contact.phone}
                            error={errors.phone}
                            placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                            maxLength={20}
                            onKeyDown={(e) => {
                                // e.key === 'Enter' && sendMessage()
                            }}
                            onChange={getInputValues}
                        />
                    </div>
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        name={'message'}
                        value={contact.message}
                        error={errors.message}
                        placeholder={staticTexts?.contacts_page_placeholder_message}
                        maxLength={500}
                        onKeyDown={(e) => {
                            // e.key === 'Enter' && sendMessage()
                        }}
                        onChange={getInputValues}
                    />
                    <button className='link-button'
                            onClick={() => {
                                // if (!requestLoading && sendMessage) {
                                sendMessage();
                                // }
                            }}>
                        {staticTexts?.contacts_page_btn_send_message}

                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : <TickIcon title={''}
                                            className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                        }

                    </button>
                </div>
                <div className="contacts-list">
                    {
                        !!contacts.length && contacts.map((item, index) => {
                            return <div key={item?.id} className={`contact-card`}>
                                <div className="image-wrapper">
                                    <div className="image-proportion">
                                        {imgArr[index]}
                                        {/*<img src={require(`../../assets/images/contacts/${item.mediaMain?.path}`)}*/}
                                        {/*     className="product-card-img"*/}
                                        {/*     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>*/}
                                    </div>
                                </div>
                                <div
                                    className="contact-title">{staticTexts[`contacts_cards_${index+1}_item`]}</div>
                            </div>
                        })
                    }
                    <div className="bg-box">
                        <div className="bg-proportion"/>
                    </div>
                </div>
            </div>
        </div>

    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};
const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
