// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import assets
import "../../assets/styles/homepage/describing-slider.scss";
import {ReactComponent as Next} from '../../assets/images/icons/ic_arrow_right.svg';
import {ReactComponent as Prev} from '../../assets/images/icons/ic_arrow_right.svg';
import {ReactComponent as Play} from '../../assets/images/icons/ic_play.svg';

// Import Components
import Slider from "react-slick";
import {SLIDER_URL_TYPES} from "../../constants/constTypes";
import {history} from "../../configs/history";
import {describingSlider} from "../../constants/staticData";
import {getPropsFromState} from "../../redux/mapStateToProps";

// Import utils

class DescribingSlider extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            itemHeight: 0,
            sliderCircleEnded: false,
            firstImageLoaded: false,
            loadedImg: false
        };
        this.pagination = React.createRef();
        this.paginationItem = React.createRef();
        this.scrollList = React.createRef();
        this.img = React.createRef();
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    getSliderIndex = (index) => {
        this.setState({
            slideIndex: index,
        });
        this.slider.slickGoTo(index);
    };

    nextSlide = (index) => {
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (describingSlider?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        this.setState({
            slideIndex: index === 0 ? describingSlider?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    openUrl = () => {
        const {slideIndex} = this.state;

        if (!!describingSlider[slideIndex]?.url) {
            window.open(describingSlider[slideIndex].url);
        } else {
            const path = !!SLIDER_URL_TYPES[describingSlider[slideIndex].type] && describingSlider[slideIndex].resourceId ?
                `${SLIDER_URL_TYPES[describingSlider[slideIndex].type]}/${describingSlider[slideIndex].resourceId}` : '/';
            history.push({
                pathname: path,
                state: {
                    title: describingSlider[slideIndex].title
                }
            })
        }
    };

    afterChangeSlideIndex = (slideIndex) => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    render() {
        const {slideIndex} = this.state;

        const settingsSlider = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            pauseOnHover: false,
            lazyLoad: 'progressive',
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return !!describingSlider?.length && <section className="slider-wrapper" id={'main'}>
            <div className="image-wrapper" onClick={this.openUrl}>
                <Slider {...settingsSlider}>
                    {describingSlider?.map((item, index) => {
                        return <div key={item.id} className={`slider-item`} ref={this.img}>
                            <picture>
                                {window.innerWidth > 700 ? <source media="(max-width:1050px)"
                                                                   srcSet={require("../../assets/images/slider/slider_tablet.jpg")}/>
                                    : <source media="(max-width:700px)"
                                              srcSet={require("../../assets/images/slider/slider_mobile.jpg")}/>}

                                <img src={require('../../assets/images/slider/slider.jpg')}
                                     onLoad={this.handleImageLoaded}
                                     alt={item?.mediaMain?.altAttribute ? item.mediaMain?.altAttribute : ''}/>
                            </picture>
                            <div className="slide-content">
                                <div
                                    className="slide-title">{this.props.staticTexts[`slider_${index + 1}_item_title`]}</div>
                                <div
                                    className="slide-description"
                                    dangerouslySetInnerHTML={{__html: this.props.staticTexts[`slider_${index + 1}_item_description`]}}/>
                                <a href={'#contacts'} className="player-btn">
                                    {/*<Play title={''}/>*/}
                                    {this.props?.staticTexts?.main_see_video}
                                </a>
                            </div>
                        </div>
                    })}
                </Slider>

               {describingSlider?.length > 1 && <div className="arrow-wrapper">
                    <div className="slider-arrow">
                        <Prev onClick={() => this.prevSlide(slideIndex)} title={''} className={'icon-prev'}/>
                    </div>
                    <div className="slider-arrow">
                        <Next onClick={() => this.nextSlide(slideIndex)} title={''}/>
                    </div>
                </div>}
            </div>
        </section>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DescribingSlider);
