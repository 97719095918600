// Import packages
import React, {Component} from "react";
import {Route, Switch, Router} from "react-router-dom";

// Import utils
import {history} from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/Homepage";
import NotFound from "../containers/NotFound";
import Locations from "../containers/Locations";

export default  class Routes extends Component {
    render() {

        return <Router history={history}>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/location" component={Locations}/>
                    {/*<Route exact path="/404" component={NotFound}/>*/}
                    <Route exact path="*" component={Homepage}/>
                </Switch>
            </Layout>
        </Router>
    }
}
