import {store} from "../redux/store";
const{staticTexts} = store.getState().general;

/*
*
*Languages
*
* */

export const languages = [
    {
        "code": "hy",
        "name": "Հայ",
        "isMain": true,
    },
    {
        "code": "en",
        "name": "Eng",
        "isMain": false,
    },
    {
        "code": "ru",
        "name": "Ру",
        "isMain": false,
    }
];

/*
*
* Slider Data
*
* */

export const describingSlider = [
    {
        "id": "5f4e59b5bcfd6a78dbca162b",
        "title": "Ձերք բերեք վառելիք շուկայական արժեքից մատչելի գնով",
        "description": "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
            "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
            " հարթակը Վառելիքի առաջին հայաստանում:",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "/slider/slide1.jpeg",
            "id": "5f638841b29ca2a4dea31afa"
        }
    },
    // {
    //     "id": "5f6385a5826eb5a50ccd8e55",
    //     "title": "Ձերք բերեք վառելիք շուկայական արժեքից մատչելի գնով",
    //     "subTitle": "The best free stock",
    //     "description": "Վառելիքի լիցքավորման առաջին առցանց հարթակը Վառելիքի առաջին հայաստանում լիցքավորման առելի ռելքի " +
    //         "լիցքավորման լիցքավորման առելիքի լիցքավորմ անառաջին իցքավորման առաջին Վառելիքի լիցքավորման առաջին առցանց" +
    //         " հարթակը Վառելիքի առաջին հայաստանում:",
    //     "mediaMain": {
    //         "dimensions": {
    //             "width": 3400,
    //             "height": 1250
    //         },
    //         "extension": "jpeg",
    //         "path": "/slider/slide2.jpeg",
    //         "id": "5f638619b29ca2a4dea31af9"
    //     }
    // }
];

/*
*
* Services
*
* */
// console.log(staticTexts?.service_1_item_title, "staticTexts?.service_1_item_title");
export const services = [
    {
        "id": "5f4e5f9b5bdfs6a78dbca162b",
        "title": staticTexts?.service_1_item_title,
        "description": staticTexts?.service_1_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "1.png",
            "id": "5f638841b29ca2a4dea31afa"
        }
    },
    {
        "id": "5f6385a78s95eb5a50ccd8e55",
        "title": staticTexts?.service_2_item_title,
        "description": staticTexts?.service_2_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "2.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895eb5a50cercd8e55",
        "title": staticTexts?.service_3_item_title,
        "description": staticTexts?.service_3_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "3.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895eb5a50ctycd8e55",
        "title": staticTexts?.service_4_item_title,
        "description": staticTexts?.service_4_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "4.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    }
];

/*
*
* Fuels
*
* */

export const fuels = [
    {
        "id": "232e59asb5bcfd6a78dbca162b",
        "title": staticTexts?.fuels_list_1_item_title,
        "description": staticTexts?.fuels_list_1_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "1.1.png",
            "id": "5f638841b29ca2a4dea31afa"
        }
    },
    {
        "id": "5f6385a5826daeb5as0ccd8e55",
        "title": staticTexts?.fuels_list_2_item_title,
        "description": staticTexts?.fuels_list_2_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "2.2.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a3246eb5as0cwqcd8e55",
        "title": staticTexts?.fuels_list_3_item_title,
        "description": staticTexts?.fuels_list_3_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "3.3.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a98qw66eb5as0ccd8e55",
        "title": staticTexts?.fuels_list_4_item_title,
        "description": staticTexts?.fuels_list_4_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "4.4.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a9866eb5as0ccwed8e55",
        "title": staticTexts?.fuels_list_5_item_title,
        "description": staticTexts?.fuels_list_5_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "5.5.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f63qq85a96856eb5as0ccd8e55",
        "title": staticTexts?.fuels_list_6_item_title,
        "description": staticTexts?.fuels_list_6_item_description,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "jpeg",
            "path": "6.6.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    }
];


/*
*
* Contacts
*
* */

export const contacts = [
    {
        "id": "5f4e59b5sbdfs6a78dbca345b",
        "title": staticTexts?.contacts_cards_1_item,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "1.png",
            "id": "5f638841b29ca2a4dea31afa"
        }
    },
    {
        "id": "5f9515aa7895eb5a50ccd8e55",
        "title": staticTexts?.contacts_cards_2_item,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "2.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895eb5ad50ccd8e55",
        "title": staticTexts?.contacts_cards_3_item,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "3.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895eb5a50ccd8ve55",
        "title": staticTexts?.contacts_cards_4_item,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "4.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895eb5a50ccdad8e55",
        "title": staticTexts?.contacts_cards_5_item,
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "5.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
];

/*
*
* Partners
*
* */

export const partners = [
    {
        "id": "5f4e59b5ewbdfs6a78dbca345b",
        "title": "Լրացրեք օնլայն հայտ",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "1.png",
            "id": "5f638841b29ca2a4dea31afa"
        }
    },
    {
        "id": "5f9515a7895erwb5a50ccd8e55",
        "title": "Ստացեք հաստատում մեր կողմից",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "2.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895erwb5a50ccd8e55",
        "title": "Ստացեք հարմարավետ հարթակ",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "3.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895edsb5a50ccd8e55",
        "title": "Լիցքավորեք ձեր հաշիվը",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "4.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a7895eb5a50ccdfs8e55",
        "title": "Օգտվեք կառավարման վահանակից",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "5.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a789b5a50ccdfs8e55",
        "title": "Օգտվեք կառավարման վահանակից",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "6.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a78sa50ccdfs8e55",
        "title": "Օգտվեք կառավարման վահանակից",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "1.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a78sa50ccdfs8e55",
        "title": "Օգտվեք կառավարման վահանակից",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "3.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
    {
        "id": "5f6385a78sa50ccdfs8e55",
        "title": "Օգտվեք կառավարման վահանակից",
        "mediaMain": {
            "dimensions": {
                "width": 3400,
                "height": 1250
            },
            "extension": "png",
            "path": "2.png",
            "id": "5f638619b29ca2a4dea31af9"
        }
    },
];