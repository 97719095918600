//----------------------------------4Steps-server---------------------------------------------------
// const ADMIN_URL = "http://173.249.20.192:2020/api/v1";
// const USERS_URL =  "http://173.249.20.192:2011/api/v1";
// const FUELS_URL =  "http://173.249.20.192:2012/api/v1";

//----------------------------------Official-server---------------------------------------------------
const ADMIN_URL =  "https://api.petrol.am/admin/api/v1";
const USERS_URL =  "https://api.petrol.am/users/api/v1";
const FUELS_URL =  "https://api.petrol.am/fuels/api/v1";

export const _urlStaticTexts = ADMIN_URL + "/others/static-texts";
export const _urlContacts = USERS_URL + "/others/contacts";
export const _urlStations = FUELS_URL + "/stations";
