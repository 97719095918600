// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Link, useLocation} from 'react-router-dom'

// Import assets
import '../assets/styles/components/footer.scss'
import {ReactComponent as CompanyIcon} from '../assets/images/4steps.svg';
import {ReactComponent as ViberIcon} from '../assets/images/icons/ic_viber.svg';
import {ReactComponent as WhatsUpIcon} from '../assets/images/icons/ic_whats_up.svg';
import {ReactComponent as MessengerIcon} from '../assets/images/icons/ic_messenger.svg';
import {ReactComponent as Logo} from '../assets/images/progect_logo.svg';
import {ReactComponent as Phone} from '../assets/images/icons/ic_phone.svg';
import {ReactComponent as Mail} from '../assets/images/icons/ic_mail.svg';

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetContacts} from "../redux/actions";
import {Checkbox, Collapse} from 'antd';
import {ReactComponent as CollapseIcon} from "../assets/images/icons/ic_arrow_down_blue.svg";

const {Panel} = Collapse;

function Footer(props) {
    const {contacts, staticTexts} = props;

    useEffect(() => {
        props.GetContacts();
        console.log(contacts, '/others/contacts');
    }, []);

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="row">
                <div className="logo-wrapper">
                    <Logo title={''}/>
                </div>
                <ul className={'addresses'}>
                    <a className="nav-item" href={'/#main'}>{props?.staticTexts?.menu_item_main}</a>
                    <a className="nav-item" href={'/#services'}>{props?.staticTexts?.menu_item_services}</a>
                    <a className="nav-item" href={'/#fuels'}>{props?.staticTexts?.menu_item_fuels}</a>
                    <a className="nav-item" href={'/#apps'}>{props?.staticTexts?.menu_item_apps}</a>
                    <a className="nav-item" href={'/#contacts'}>{props?.staticTexts?.menu_item_contacts}</a>
                    <a className="nav-item" href={'/#partners'}>{props?.staticTexts?.menu_item_partners}</a>
                </ul>
            </div>
            <div className="row">
                <div className={'info'}>
                    <a href={`mailto:${contacts?.email}`}>
                        <div className="info-item"><Mail title={''}/>{contacts?.email}</div>
                    </ a>
                    <a href={`tel:${contacts?.phoneNumber1}`}>
                        <div className="info-item"><Phone title={''}/>{contacts?.phoneNumber1}</div>
                    </ a>
                </div>
                <div className={'social'}>
                    <div className="social-title">{staticTexts?.footer_social_title}</div>

                    <div className="icons-wrapper">
                       {contacts?.whatsApp &&
                       <a href={`https://api.whatsapp.com/send?phone=${contacts?.whatsApp}`} target={'_blank'}
                          target={'_blank'}>
                            <WhatsUpIcon title={''}/>
                        </ a>}
                       {contacts?.viber &&
                       <a href={`viber://chat?number=${contacts?.viber.includes('+') ? contacts?.viber.replace('+', '') : contacts?.viber}`}
                          target={'_blank'}>
                            <ViberIcon title={''}/>
                        </ a>}
                       {contacts?.messenger &&
                       <a href={`http://m.me/mybot?ref=${contacts?.messenger}`} target={'_blank'}>
                            <MessengerIcon title={''}/>
                        </ a>}
                    </div>
                </div>
            </div>

            <div className="bottom-side">
                <div className="copyright">
                    {staticTexts?.footer_section_copyright_text}
                </div>
                <div className='developed-by'>
                    <span> {staticTexts?.footer_section_developed_by_text}</span>
                    <a href={"https://4steps.am/"} target={'_blank'}>
                        <CompanyIcon title=''/>
                    </a>
                </div>
            </div>
        </div>
        {/*        <div className="mobile-footer-wrapper">
            <div className="top-part">
                <div className="logo-wrapper">
                    <img src={require("../assets/images/progect_logo.svg")} alt=""/>
                </div>
                <div className="icons-wrapper">
                    <a href={contacts?.facebookUrl} target={'_blank'}><FbIcon className='social-icon' title=''/></a>
                    <a href={contacts?.instagramUrl} target={'_blank'}><InstaIcon className='social-icon' title=''/></a>
                    <a href={contacts?.twitterUrl} target={'_blank'}><TwIcon className='social-icon' title=''/></a>
                </div>
            </div>

            {!!categories?.length && <Collapse expandIconPosition={'right'}
                                               expandIcon={(value) => {
                                                   return <CollapseIcon className={value.isActive ? 'rotate' : ''}/>
                                               }}>
                <Panel header={staticTexts?.footer_section_categories} key="1">
                    {!!categories?.length && categories?.map(item => {
                        return <Link to={`/category/${item.id}`}
                                     key={item.id}>
                            <p className={'link-item'}>{item.title}</p>
                        </Link>
                    })}
                </Panel>
            </Collapse>}

            {!!banners?.length && <Collapse expandIconPosition={'right'}
                                            expandIcon={(value) => {
                                                return <CollapseIcon className={value.isActive ? 'rotate' : ''}/>
                                            }}>
                <Panel header={staticTexts?.footer_section_banners} key="1">
                    {!!banners?.length && categories?.map(item => {
                        return <Link to={{pathname: `/market/${item?.id}`}}
                                     key={item.id}>
                            <p className={'link-item'}>{item.title}</p>
                        </Link>
                    })}
                </Panel>
            </Collapse>}

            {!!sections?.length && <Collapse expandIconPosition={'right'}
                                             expandIcon={(value) => {
                                                 return <CollapseIcon className={value.isActive ? 'rotate' : ''}/>
                                             }}>
                <Panel header={staticTexts?.footer_section_suggestions} key="1">
                    {!!sections?.length && sections?.map(item => {
                        return <Link to={{
                            pathname: `/section-details/${item.id}`,
                            state: {title: item.title}
                        }} key={item.id}>
                            <p className={'link-item'}>{item.title}</p>
                        </Link>
                    })}
                </Panel>
            </Collapse>}

            <div className="bottom-side">
                <div className="copyright">
                    {staticTexts?.footer_section_copyright_text}
                </div>
                <div className='developed-by'>{staticTexts?.footer_section_developed_by_text}
                    <a href={"https://4steps.am/"} target={'_blank'}>
                        <CompanyIcon title=''/>
                    </a>
                </div>
            </div>
        </div>*/}
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
    ])
};

const mapDispatchToProps = {GetContacts};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
