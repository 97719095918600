import axios from "axios";
import {store} from "../store";
import {UTIL_CONSTS} from "../constants";

export const request = ({url, method = "GET", data = null, token = false, customHeaders = null, languageFlag = false, newToken = null}) => {

    let headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
    customHeaders && (headers={...headers,...customHeaders});
    let language = store.getState()?.general?.selectedLanguage;

    if (languageFlag) {
        // headers["Accept-Language"] = language?.code ?? 'hy'
        headers["Accept-Language"] = 'hy'
    }
    const requestData = {
        url: url,
        method: method,
        headers: headers,
    };

    data && (requestData.data = data);

    return axios.request(requestData)
};

// log user out when token expires

axios.interceptors.response.use(response => response, error => {
        const originalRequest = error.config;

        if (originalRequest.url.slice(-13) === "refresh-token") {
            // if refresh token has been expired
        } else {
            // interceptors exit
            store.dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        }
        return Promise.reject(error);
    }
);
