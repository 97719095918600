// Import packages
import React from "react";

// Import utils
import {history} from "../../configs/history";

// Import styles
import "../../assets/styles/components/uiElements/buttons.scss";

import {LoadingOutlined} from '@ant-design/icons';

export function LinkButton(props) {
    const {title, className, link, cb, loading, disabled, loadingText} = props;
    return <button className={`link-button ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={() => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               cb();
                           }
                       }
                   }}>
        {loading ?
            <div className="flex-row">{loadingText}
                <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
            </div> :
            <span className="title">{title}</span>}
    </button>
}