// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "../../assets/styles/homepage/partners.scss";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";

//Import Components

// Import utils
import {partners} from "../../constants/staticData";

class Partners extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.partners?.length - 1 < itemCount,
        });

    }

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.partners?.length,
        });
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1600) return 9;
        else if (width > 1400) return 8;
        else if (width > 1200) return 7;
        else if (width > 1024) return 6;
        else if (width > 800) return 5;
        else if (width > 600) return 4;
        else return 3;
    };

    render() {
        const settings = {
            dots: false,
            arrows: false,
            speed: 500,
            slidesToShow: 8,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            centerMode:true,
            centerPadding: '40px 0 0 0',
            ref: partner => (this.partners = partner),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1700,
                    settings: {
                        slidesToShow: 7,
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                // {
                //     breakpoint: 800,
                //     settings: {
                //         slidesToShow: 3,
                //     }
                // },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        centerPadding: '50px 0 0 0',
                        slidesToShow: 2,
                    }
                },
            ]
        };

        return !!partners?.length && <div className="partners-wrapper" id='partners'>
            <div className="partners-title">{this.props?.staticTexts?.partners_title}</div>

            <div className="partners-list-wrapper">
                {
                    <Slider {...settings}>
                        {partners?.map(item => {
                            return <a href={item.url} target={'_blank'} key={item?.id}>
                                <div className="partner-item" key={item.id}>
                                    <div className="partner-content">
                                        {item.mediaMain?.path && <img src={require(`../../assets/images/partners/${item.mediaMain?.path}`)}
                                             alt={item?.mediaMain?.altAttribute ? item.logoPath?.altAttribute : ''}/>}
                                    </div>
                                </div>
                            </a>
                        })}
                    </Slider>
                }
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(Partners);
