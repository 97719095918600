
export const NOTIFICATION_ROW_MODES = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};
export const SORT_TYPES = {
    price: 'Գնի',
    updatedAt: 'Ամսաթվի',
};

export const UNIT_TYPES = {
    KILOGRAM: 'Կգ',
    LITER: 'լ',
    PIECES: 'հատ',
    BOX: 'տուփ',
};

export const PAYMENTS_TYPES = {
    CARD:'CARD',
    CASH:'CASH',
    BONUS_CARD:'BONUS_CARD'
};

export const SLIDER_URL_TYPES = {
    PRODUCT:'/product-details',
    SECTION:'/section-details',
    CUSTOM_PAGE:'/custom-page',
    BANNER:'/market',
};

export const BONUS_HISTORY_TYPES = {
    ORDER_VIA_BONUS_CARD: {
        color: '#FF3232',
        symbol: '-',
    },
    BONUS_FROM_ORDER: {
        color: '#005EB1',
        symbol: '+',
    },
};
