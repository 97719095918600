// import packages
import React from "react";
import {connect} from "react-redux";

//import assets
import '../../assets/styles/header/navigation-row.scss'
import {ReactComponent as LocationIcon} from '../../assets/images/icons/ic_location.svg';

// Import utils
import {ChangeLanguage} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {Link} from "react-router-dom";


function NavigationRow(props) {
    return <div className={`navigation-wrapper `}>
        <div className={`header-location`}>
            <Link to={'location'}>
                <LocationIcon title={''} className={'location-icon'}/>
                {props?.staticTexts?.nav_our_fuels}
            </Link>
        </div>
        <div className="nav-bar">
            <a className="nav-item" href={'/#main'}>{props?.staticTexts?.menu_item_main}</a>
            <a className="nav-item" href={'/#services'}>{props?.staticTexts?.menu_item_services}</a>
            <a className="nav-item" href={'/#fuels'}>{props?.staticTexts?.menu_item_fuels}</a>
            <a className="nav-item" href={'/#apps'}>{props?.staticTexts?.menu_item_apps}</a>
            <a className="nav-item" href={'/#contacts'}>{props?.staticTexts?.menu_item_contacts}</a>
            <a className="nav-item" href={'/#partners'}>{props?.staticTexts?.menu_item_partners}</a>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationRow)
