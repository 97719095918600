// import packages
import React from "react";
import {connect} from "react-redux";

//import assets
import "../assets/styles/components/header.scss";
import {ReactComponent as Logo} from "../assets/images/progect_logo.svg";
import {ReactComponent as GooglePlay} from "../assets/images/download-google-play-badge.svg";
import {ReactComponent as AppleStore} from "../assets/images/download-app-store-badge.svg";
import {ReactComponent as PhoneIcon} from "../assets/images/icons/ic_phone.svg";

// Import Components
import NavigationRow from "./header/NavigationRow";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {ChangeLanguage} from "../redux/actions";
import {Menu, Dropdown} from "antd";
import {languages} from "../constants/staticData";

function Header(props) {
    const {selectedLanguage, contacts} = props;

    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.code}
                                   onClick={() => {
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            {item.name}
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <div className={`header-wrapper `}>
        <section className={`header-box-wrapper`}>
            <a href={'/#main'} className="header-logo">
                <Logo title={''}/>
            </a>

            <div className="right-side">
                <a href={`tel:${contacts?.phoneNumber1}`}>
                    <div className="service-center">
                        <PhoneIcon title='' className={'service-center-icon'}/>
                        <span>{props?.staticTexts?.service_center}</span>
                    </div>
                </ a>
                <div className={`apps-wrapper`}>
                    <a href="https://play.google.com/store/apps/details?id=am.foursteps.petrolam"
                       className={'google-play'}
                       target={'_blank'}>
                        <GooglePlay title={''}/>
                    </a>
                    <a href="https://apps.apple.com/us/app/petrol-am/id1509265936?ls=1"
                       target={'_blank'}>
                        <AppleStore title={''}/>
                    </a>
                </div>
                <div className="languages-wrapper">
                    <Dropdown overlay={menuLanguage}
                              className="language-button"
                              overlayClassName={''}
                              placement="bottomCenter"
                              size={'small'}>
                        <div className="dropdown-menu-item">
                            {selectedLanguage?.name}
                        </div>
                    </Dropdown>
                </div>
            </div>
        </section>

        <NavigationRow/>

        {/*Mobile Menu*/}

        <div className="mobile-nav-bar">
            <a href={'/#main'}>
                {/*<img src={require('../assets/images/progect_logo.svg')} className={'mobile-logo'} alt=""/>*/}
                <Logo title={''} className={'mobile-logo'}/>
            </a>
            <div className={`nav-bar-menu ${props.mobileMenuIsOpen ? 'active' : ''}`}
                 onClick={props.toggleMobileMenu}>
                <span className="line line-1"/>
                <span className="line line-2"/>
                <span className="line line-3"/>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'contacts',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
