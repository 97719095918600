import {
    request,
    _urlStaticTexts,
    _urlContacts,
    _urlLanguage, _urlStations,
} from "../api";
import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

/*export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};*/

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetContacts = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CONTACTS,
                    payload: data
                })
            })
    };
};

export const GetStations = () => {
    const requestData = {
        url: `${_urlStations}`,
        method: "GET",
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIONS,
                    payload: data
                })
            })
    };
};

export const SendEmail = (data) => {
    const requestData = {
        url: `${_urlContacts}/send`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(()=>{
            dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};


