// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import GoogleMapReact from 'google-map-react';
import moment from "moment";

//import styles
import "../assets/styles/containers/locations.scss";
import {ReactComponent as CloseIcon} from '../assets/images/icons/ic_close.svg';
import {ReactComponent as PhoneIcon} from '../assets/images/icons/ic_phone.svg';
import {ReactComponent as ClockIcon} from '../assets/images/icons/ic_clock_grey.svg';
import {ReactComponent as Logo} from '../assets/images/mobile_logo.svg';
import {ReactComponent as Location} from '../assets/images/icons/ic_location_black.svg';

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GOOGLE_MAP_KEY} from "../configs/variables";
import {MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM} from "../constants/constants";
import {GetStations} from "../redux/actions";

function Locations(props) {
    const [openWindow, setOpenWindow] = useState(false);
    const [location, setLocation] = useState({});
    useEffect(()=>{
        props.GetStations()
    },[]);

    let defaultAddress = props.address?.lat && props?.address?.lng ? {
        lat: props.address?.lat,
        lng: props.address?.lng
    } : MAP_DEFAULT_CENTER;

    const toggleLocation = (item) => {
        setOpenWindow(true);
        setLocation(item);
        // openLocation();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    const closeInfo = () => {
        setOpenWindow(false);
        setLocation({});
    };

   /* const openLocation = () => {
        // notification.open({
        //     className: "station-info-box",
        //     key: location?.id,
        //     message: (location?.region || location?.residence) && (location?.region +', '+ location?.residence),
        //     description: <div className={'box-content'}>
        //       {location?.stationAddress && <div className={'station-address'}>
        //           {location?.residence}
        //           {location?.stationAddress}
        //       </div>}
        //        {location?.workingHours?.openingTime && <div className={'station-working-hours'}>
        //            <ClockIcon title={''}/>
        //            {location?.workingHours?.openingTime+" "+ location?.workingHours?.closingTime}
        //        </div>}
        //         <div className={'station-working-hours'}>
        //             <PhoneIcon title={''}/>
        //             011222333
        //             {location?.phoneNumber}
        //         </div>
        //     </div>,
        //     // onClick: () => {
        //     //     console.log('Notification Clicked!');
        //     // },
        //     placement: 'bottomLeft',
        //     duration: 0,
        //     closeIcon: <CloseIcon title={''}/>,
        //     onClose: close,
        // });
        // return
    };*/

    return <div className={`locations-wrapper`}>
        <div className={'map'}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: GOOGLE_MAP_KEY,
                    libraries: 'places'
                }}
                defaultCenter={defaultAddress}
                center={defaultAddress}
                distanceToMouse={() => {
                    // console.log('distanceToMouse');
                }}
                defaultZoom={MAP_DEFAULT_ZOOM}
                yesIWantToUseGoogleMapApiInternals={true}
            >
                {
                    !!props.stations?.length && props.stations?.map(item => {
                        return <Marker lat={item?.location?.latitude}
                                       lng={item?.location?.longitude}
                                       toggleLocation={() => toggleLocation(item)}
                                       className={location?.id === item?.id ? 'selected' : ''}
                                       key={item.id}/>
                    })
                }
            </GoogleMapReact>
        </div>
      <div className={`station-info-box ${openWindow ? 'visible-box' : ''}`}>
            <div className="header">
                {location?.region && <span>{location?.region}</span>}
                <CloseIcon title={''}
                           onClick={closeInfo}
                           className={'close-icon'}/>
            </div>
            <div className={'box-content'}>
                {location?.stationAddress && <div className={'station-address'}>
                    {location?.residence && <span>{location?.residence}</span>},&nbsp;
                    {location?.stationAddress}
                </div>}
                {location?.workingHours?.openingTime && <div className={'station-working-hours'}>
                    <ClockIcon title={''}/>
                    {location?.workingHours?.openingTime?.slice(0,5)+"-"+ location?.workingHours?.closingTime?.slice(0,5)}
                </div>}
                <div className={'station-working-hours'}>
                    <PhoneIcon title={''}/>
                    {location?.phoneNumber}
                </div>
            </div>
        </div>
    </div>
}

const Marker = (props) => <div className={`marker ${props.className}`} onClick={props.toggleLocation}>
    <Location title={''} />
</div>;

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'contacts',
        'stations',
    ])
};

const mapDispatchToProps = {
    GetStations
};


export default connect(mapStateToProps, mapDispatchToProps)(Locations);
