import {combineReducers} from "redux";

// import reducers
import general from './general';
import utils from "./utils";

export default combineReducers({
    general,
    utils,
});
