// import packages
import React from "react";

//import assets
import "../../assets/styles/homepage/fuels.scss";

// Import Components
import {fuels} from "../../constants/staticData";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";

// Import utils


const Fuels = (props) => {
    const {staticTexts} = props;

    return <section className={`fuels-wrapper`} id={'fuels'}>
        <div className="fuels-title">{staticTexts?.fuels_title}</div>
        <div className="banner-wrapper">
            <div className="banner">
                <div className="image-proportion">
                    <picture>
                        <source media="(max-width:700px)"
                                      srcSet={require("../../assets/images/fuels/left_bg_mobile.jpg")}/>

                        <img src={require('../../assets/images/fuels/left_bg.jpg')} alt=""/>
                    </picture>
                </div>
                <div className="banner-content left">
                    <div className="title">{staticTexts?.fuels_first_banner_title}</div>
                    <ol>
                        <li><span>{staticTexts?.fuels_first_banner_1_item}</span></li>
                        <li><span>{staticTexts?.fuels_first_banner_2_item}</span></li>
                        <li><span>{staticTexts?.fuels_first_banner_3_item}</span></li>
                        <li><span>{staticTexts?.fuels_first_banner_4_item}</span></li>
                    </ol>
                </div>
            </div>
            <div className="banner">
                <div className="image-proportion">
                    <picture>
                        <source media="(max-width:700px)"
                                srcSet={require("../../assets/images/fuels/right_bg_mobile.jpg")}/>

                        <img src={require('../../assets/images/fuels/right_bg.jpg')} alt=""/>
                    </picture>
                </div>
                <div className="banner-content right">
                    <div className="title">{staticTexts?.fuels_second_banner_title}</div>
                    <ol>
                        <li><span>{staticTexts?.fuels_second_banner_1_item}</span></li>
                        <li><span>{staticTexts?.fuels_second_banner_2_item}</span></li>
                        <li><span>{staticTexts?.fuels_second_banner_3_item}</span></li>
                        <li><span>{staticTexts?.fuels_second_banner_4_item}</span></li>
                    </ol>
                </div>
            </div>
        </div>
        <div className="fuels-list">
                {
                    !!fuels.length && fuels.map((item, index) => {
                        return <div key={item?.id} className={`fuel-card`}>
                            <div className="card-proportion">
                                <div className="card-inner">
                                    <div className="fuel-title">{staticTexts[`fuels_list_${index+1}_item_title`]}</div>
                                </div>
                                <div className="image-wrapper">
                                    <div className="image-proportion">
                                        <img src={require(`../../assets/images/fuels/${item.mediaMain?.path}`)}
                                             className="product-card-img"
                                             alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
        </div>
    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts'
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Fuels);
