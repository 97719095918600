// Import packages
import React from "react";

// Import assets
import '../../assets/styles/homepage/app.scss';
import {ReactComponent as GooglePlay} from "../../assets/images/download-google-play-badge.svg";
import {ReactComponent as AppleStore} from "../../assets/images/download-app-store-badge.svg";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";

// Import components

// Import utils

const App = (props) => {
    const {staticTexts} = props;

    return <section className={`app-wrapper`} id='apps'>
        {/*<div className="app-title">{staticTexts?.apps_title}</div>*/}
        <div className="app-content">
            <div className="app-inner">
                <div className="app-info">
                    <div className="title">{staticTexts?.apps_info_title}</div>
                    <p>{staticTexts?.apps_info_description}</p>
                    <div className="app-buttons">
                        {/*<GooglePlay title={''}/>*/}
                        {/*<AppleStore title={''}/>*/}
                        <a href="https://play.google.com/store/apps/details?id=am.foursteps.petrolam"
                           target={'_blank'}>
                            <img src={require('../../assets/images/download-google-play-badge.svg')} className={'google-play'} alt=""/>
                        </a>
                        <a href="https://apps.apple.com/us/app/petrol-am/id1509265936?ls=1"
                           target={'_blank'}>
                            <img src={require('../../assets/images/download-app-store-badge.svg')} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
};

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts'
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);