// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

// Import assets
import '../../assets/styles/header/mobile-menu.scss';
import {ReactComponent as PhoneIcon} from "../../assets/images/icons/ic_phone.svg";

// Import components
import {Menu, Dropdown} from 'antd';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {languages} from "../../constants/staticData";
import {ReactComponent as GooglePlay} from "../../assets/images/download-google-play-badge.svg";
import {ReactComponent as AppleStore} from "../../assets/images/download-app-store-badge.svg";
import {ReactComponent as LocationIcon} from "../../assets/images/icons/ic_location.svg";

const MobileMenu = memo((props) => {
    const {
        className, toggleMobileMenu, selectedLanguage, contacts
    } = props;


    const menuLanguage = (
        <Menu className="languages-dropdown">
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.code}
                                   onClick={() => {
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            {item.name}
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return <section className={`mobile-menu-wrapper ${className}`}>
        <div className={`mobile-menu-content ${className}`}>
            <div className="nav-bar">
                <a className="nav-item" href={'/#main'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_main}</a>
                <a className="nav-item" href={'/#services'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_services}</a>
                <a className="nav-item" href={'/#fuels'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_fuels}</a>
                <a className="nav-item" href={'/#apps'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_apps}</a>
                <a className="nav-item" href={'/#contacts'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_contacts}</a>
                <a className="nav-item" href={'/#partners'}
                   onClick={toggleMobileMenu}>{props?.staticTexts?.menu_item_partners}</a>
            </div>
            <div className="languages-wrapper">
                <span>{props?.staticTexts?.mobile_menu_language}</span>
                <Dropdown overlay={menuLanguage}
                          className="language-button"
                          overlayClassName={''}
                          placement="bottomCenter"
                          size={'small'}>
                    <div className="dropdown-menu-item">
                        {selectedLanguage?.name}
                    </div>
                </Dropdown>
            </div>
            <div className="top-side">
                <a href={`tel:${contacts?.phoneNumber1}`} className="phone-link">
                    <div className="service-center">
                        <PhoneIcon title='' className={'service-center-icon'}/>
                        <span>{props?.staticTexts?.service_center}</span>
                    </div>
                </ a>
                <Link to={'location'}>
                    <div className="map-button">
                        <LocationIcon title={''} className={'location-icon'}/>
                        {/*{props?.staticTexts?.nav_our_fuels}*/}
                    </div>
                </Link>
            </div>
            <div className={`apps-wrapper`}>
                {/*<GooglePlay className="google-play" title={''}/>*/}
                {/*<AppleStore title={''}/>*/}
                <a href="https://play.google.com/store/apps/details?id=am.foursteps.petrolam"
                   className={'google-play'}
                   target={'_blank'}>
                    <img src={require('../../assets/images/download-google-play-badge.svg')} alt=""/>
                </a>
                <a href="https://apps.apple.com/us/app/petrol-am/id1509265936?ls=1"
                   target={'_blank'}>
                    <img src={require('../../assets/images/download-app-store-badge.svg')} alt=""/>
                </a>
            </div>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>

    </section>
});

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);