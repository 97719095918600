// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import Components;
import DescribingSlider from "../components/homepage/DescribingSlider";
import Services from "../components/homepage/Services";
import Fuels from "../components/homepage/Fuels";
import Contacts from "../components/homepage/Contacts";
import App from "../components/homepage/App";
import Partners from "../components/homepage/Partners";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";

class Homepage extends Component {

    render() {
        return <div className="homepage-wrapper">
            <DescribingSlider/>
            <Services/>
            <Fuels/>
            <App/>
            <Contacts/>
            <Partners/>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
